
import { Component, Vue } from 'vue-property-decorator'
import { DataList } from '@/types/scheduling'

@Component({
  name: 'scheduling'
})
export default class ScheduleManage extends Vue {
  private tableData: DataList[] =[]
  private dateRange: any = []
  private info: {projectId: string}= {
    projectId: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getData()
  }

  // 新增/编辑后重新获取数据
  activated () {
    this.getData()
  }

  showColumn (row: DataList) {
    return !this.$dayjs(row.schedulingDate).isBefore(this.$dayjs(Date.now() - 24 * 60 * 60 * 1000)) && row.projectState !== '3'
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.recruitUse.selectYhWorkerRecordByPage, {
      ...this.info,
      startDate: this.dateRange ? this.dateRange[0] || '' : '',
      endDate: this.dateRange ? this.dateRange[1] || '' : '',
      page: this.page,
      size: this.size
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  onAdd () {
    this.$router.push({ name: 'scheduleListAdd' })
  }

  // 编辑
  onEdit (row: DataList) {
    this.$router.push({ name: 'scheduleListEdit', params: { recordId: row.recordId || '' } })
  }

  // 查看
  onDetail (row: DataList) {
    this.$router.push({ name: 'ScheduleDetail', params: { recordId: row.recordId || '' } })
  }

  // 删除
  onDelete (recordId: string) {
    this.$confirm('确认删除该排班信息?, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.recruitUse.deleteDataYhWorkerRecord, {
        recordId: recordId
      }).then(() => {
        this.$message.success('删除成功')
        this.getData()
      })
    })
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }
}
